import React, { lazy } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import { InventorySelectedProvider } from 'hooks/InventoryContext';

// import { InventorySelectedProvider } from '/InventoryContext';

import { ChangeLog } from 'features/ChangeLog';
import { Integration } from 'features/Integration';
import FollowProductAge from 'features/FollowProductAge';
import FollowWave from 'features/FollowWave';
import ExitReport from 'features/ReportsBatch/index';
import Route from './route';

import Error400 from '../pages/Errors/400';
import Error401 from '../pages/Errors/401';
import Pallet from '../app/WMS/resources/Reports/Pallet';

import Token from '../pages';
import ProdutivityExpedition from '../app/WMS/resources/Reports/ProductivityExpedition';
import CreateComposition from '../app/WMS/resources/Composition/CreateComposition';
import StoreReceivement from '../app/Store/resources/StoreReceivement';

const Home = lazy(() => import('features/Home'));
const ReportKardex = lazy(() => import('features/ReportKardex'));
const Composition = lazy(() => import('features/Composition'));
const PickingFollow = lazy(() => import('features/Picking/FollowPicking'));
const ConferenceFollow = lazy(
  () => import('features/Picking/FollowConference')
);
const OrderReverse = lazy(() => import('features/Order/Reverse'));
const NewReceivement = lazy(() => import('features/Receivement'));
const RegisterOrder = lazy(() => import('features/Order/Register'));
const Wave = lazy(() => import('features/Picking/Wave'));
const Address = lazy(() => import('features/Storage/Addresses'));
const Conference = lazy(() => import('features/Picking/Conference'));
const Boarding = lazy(() => import('features/Boarding'));
const Query = lazy(() => import('features/Expedition'));
const ReportMenu = lazy(() => import('features/ReportMenu'));
const NewFollow = lazy(() => import('features/Order/Follow'));
const Insufficient = lazy(() => import('features/Insufficient'));
const Transference = lazy(() => import('features/Transference'));
const Ressuply = lazy(() => import('features/Resupply'));
const Box = lazy(() => import('features/Box'));
const ProdutivityConference = lazy(
  () => import('features/ProductivityConference')
);

const ProdutivityStorage = lazy(() => import('features/ProductivityStorage'));
const Components = lazy(() => import('features/Components'));
const ReportReceivement = lazy(() => import('features/ReportReceivement'));
const InvoiceDivergent = lazy(() => import('features/InvoiceDivergent'));
const ReportSeparation = lazy(() => import('features/ReportSeparation'));
const ReportStock = lazy(() => import('features/ReportStock'));
const ReportConference = lazy(() => import('features/ReportConference'));
const NewLog = lazy(() => import('features/Log/newLog'));
const Structure = lazy(() => import('features/Storage/Structure'));
const Dashboard = lazy(() => import('features/Dashboard'));
const Parameters = lazy(() => import('features/Parametes'));
const RegisterProduct = lazy(() => import('features/Product/Register'));
const receivementDashboard = lazy(
  () => import('features/Receivement/receivementDashboard/index')
);
const ReportBatchEntry = lazy(() => import('features/ReportBatchEntry/index'));
const Printhome = lazy(() => import('features/Printhome'));
const Pack = lazy(() => import('features/Print/FollowPack'));
const StockPack = lazy(() => import('features/StockPack'));
const AgentConect = lazy(() => import('features/Print/AgentConect'));
const CreatePack = lazy(() => import('features/Print/CreatePack'));
const ConferenceBox = lazy(() => import('features/Print/DistribuitionBox'));
const ReportVisit = lazy(() => import('features/VisitPicking'));
const ReportOrder = lazy(() => import('features/ReportOrder'));
const IndicatorOrder = lazy(() => import('features/Inidicator/Orders'));
const IndicatorReceivement = lazy(
  () => import('features/Inidicator/Receivement')
);
const IndicatorPicking = lazy(() => import('features/Inidicator/Picking'));
const StreetOccupations = lazy(() => import('features/Inidicator/Street'));
const CreateSupply = lazy(() => import('features/Supply/Create'));
const Screening = lazy(() => import('features/CrossDocking/Screening'));
const DriversAdmin = lazy(() => import('features/CrossDocking/DriversAdmin'));
const VechiclesAdmin = lazy(() => import('features/CrossDocking/Vehicles'));
const RoutesAdmin = lazy(() => import('features/CrossDocking/RoutesVehicles'));
const StockDocking = lazy(() => import('features/CrossDocking/Stock'));
const Collect = lazy(() => import('features/Quality/Collect'));
const ApprovedQuality = lazy(() => import('features/Quality/Approved'));
const SubRomanio = lazy(() => import('features/SubRomanio/Create'));
const FollowSubRomanio = lazy(() => import('features/SubRomanio/Follow'));

// PrintOut
const PrintOutByBox = lazy(() => import('features/Print/PrintByBox'));
const PrintOutByPallet = lazy(() => import('features/Print/PrintByPallet'));
const PrintByAddress = lazy(() => import('features/Print/PrintByAddress'));
const PrintByPicking = lazy(() => import('features/Print/PrintByPicking'));
const PrintByClient = lazy(() => import('features/Print/PrintByClient'));
const PrintByDanfe = lazy(() => import('features/Print/PrintByDanf'));
const PrintByEmail = lazy(() => import('features/Print/PrintByMail'));
const PrintByProduct = lazy(() => import('features/Print/PrintByProduct'));
const Distribution = lazy(() => import('features/Print/DistribuitionBox'));

// PrintOutBureau
const PrintByPackBureau = lazy(() => import('features/PrintBureau/FollowPack'));
const PrintByDistribuition = lazy(
  () => import('features/PrintBureau/DistruitionBox')
);
const PrintDistribuitionBox = lazy(
  () => import('features/PrintBureau/Printhome')
);

// Inventario
const Inventory = lazy(() => import('features/Inventory/Bording'));
const InitialInventory = lazy(() => import('features/Inventory/Intial'));
const ScoreReport = lazy(() => import('features/Inventory/Score'));
const DivergentInventory = lazy(() => import('features/Inventory/Divergent'));
const StatusAddressInventory = lazy(
  () => import('features/Inventory/StatusAddress')
);
const ProductDivergent = lazy(
  () => import('features/Inventory/DivergentProduct')
);
const Paremetrization = lazy(
  () => import('features/Inventory/Parametrization')
);
const ProductivityInventory = lazy(
  () => import('features/Inventory/Productivity')
);
const LogAddress = lazy(() => import('features/Inventory/LogAddress'));
const AddressDuplicateds = lazy(
  () => import('features/Inventory/AddressDuplicated')
);
const InventoryFinally = lazy(() => import('features/Inventory/Finally'));
const InventoryDashboard = lazy(() => import('features/Inventory/Dashboard'));

const routes: React.FC = () => {
  return (
    <BrowserRouter>
      <InventorySelectedProvider>
        <Switch>
          <Route path="/auth" exact component={Token} />
          <Route path="/wms/" exact isInside isPrivate component={Dashboard} />
          <Route path="/wms/home/" exact isInside isPrivate component={Home} />
          <Route
            path="/wms/structure_addresses"
            exact
            component={Structure}
            isPrivate
            isInside
          />
          <Route
            path="/wms/picking"
            exact
            component={PickingFollow}
            isPrivate
            isInside
          />
          <Route
            path="/wms/pickingConference"
            exact
            component={PickingFollow}
            isPrivate
            isInside
          />
          <Route
            path="/wms/pickingSeparation"
            exact
            component={PickingFollow}
            isPrivate
            isInside
          />
          <Route
            path="/wms/followConference/"
            exact
            component={ConferenceFollow}
            isPrivate
            isInside
          />
          <Route
            path="/wms/resupply"
            exact
            component={Ressuply}
            isPrivate
            isInside
          />
          <Route
            path="/wms/picking/:codeStatus"
            exact
            component={PickingFollow}
            isPrivate
            isInside
          />
          <Route
            path="/wms/wave/create/:waveCode?"
            exact
            component={Wave}
            isPrivate
            isInside
          />
          <Route
            path="/wms/conference"
            exact
            component={Conference}
            isPrivate
            isInside
          />
          <Route
            path="/wms/register_product"
            exact
            component={RegisterProduct}
            isInside
            isPrivate
          />
          <Route
            path="/wms/followOrder"
            exact
            component={NewFollow}
            isPrivate
            isInside
          />
          <Route
            path="/wms/followOrder/:codeStatus"
            exact
            component={NewFollow}
            isPrivate
            isInside
          />
          <Route
            path="/wms/reverseOrder"
            exact
            component={OrderReverse}
            isPrivate
            isInside
          />
          <Route
            path="/wms/register_order"
            exact
            component={RegisterOrder}
            isPrivate
            isInside
          />
          <Route
            path="/wms/addresses"
            exact
            component={Address}
            isPrivate
            isInside
          />
          <Route path="/wms/log" exact component={NewLog} isPrivate isInside />
          <Route
            path="/wms/receive/"
            exact
            component={NewReceivement}
            isPrivate
            isInside
          />
          <Route
            path="/receiving/receive/"
            exact
            component={StoreReceivement}
            isPrivate
            isInside
          />
          <Route
            path="/wms/Insufficient"
            exact
            component={Insufficient}
            isPrivate
            isInside
          />
          <Route
            path="/wms/transference"
            exact
            component={Transference}
            isPrivate
            isInside
          />
          <Route
            path="/wms/ProdutivityStorage"
            exact
            component={ProdutivityStorage}
            isPrivate
            isInside
          />
          <Route
            path="/wms/ProdutivityConference"
            exact
            component={ProdutivityConference}
            isPrivate
            isInside
          />
          <Route
            path="/wms/produtivityExpedition"
            exact
            component={ProdutivityExpedition}
            isPrivate
            isInside
          />
          <Route
            path="/wms/expedition"
            exact
            component={Query}
            isPrivate
            isInside
          />
          <Route
            path="/wms/MenuReport"
            exact
            component={ReportMenu}
            isPrivate
            isInside
          />
          <Route path="/wms/box" exact component={Box} isPrivate isInside />
          <Route
            path="/wms/pallet"
            exact
            component={Pallet}
            isPrivate
            isInside
          />
          <Route
            path="/wms/structure"
            exact
            component={Structure}
            isPrivate
            isInside
          />
          <Route
            path="/wms/boarding"
            exact
            component={Boarding}
            isPrivate
            isInside
          />
          <Route
            path="/wms/report-kardex"
            exact
            component={ReportKardex}
            isPrivate
            isInside
          />
          <Route
            path="/wms/components"
            exact
            component={Components}
            isPrivate
            isInside
          />
          <Route
            path="/wms/composition"
            exact
            component={Composition}
            isPrivate
            isInside
          />
          <Route
            path="/wms/composition/create/:id"
            exact
            component={CreateComposition}
            isPrivate
            isInside
          />
          <Route
            path="/wms/invoicereport/"
            exact
            component={InvoiceDivergent}
            isPrivate
            isInside
          />
          <Route
            path="/wms/receivementReport/"
            exact
            component={ReportReceivement}
            isPrivate
            isInside
          />
          <Route
            path="/wms/pickingReport/"
            exact
            component={ReportSeparation}
            isPrivate
            isInside
          />
          <Route
            path="/wms/inventory-monitoring/"
            exact
            component={ReportStock}
            isPrivate
            isInside
          />
          <Route
            path="/wms/conferenceReport/"
            exact
            component={ReportConference}
            isPrivate
            isInside
          />
          <Route
            path="/wms/reportStock/"
            exact
            component={ReportOrder}
            isPrivate
            isInside
          />
          <Route
            path="/wms/parameter/"
            exact
            component={Parameters}
            isPrivate
            isInside
          />
          <Route
            path="/wms/changelog/"
            exact
            component={ChangeLog}
            isPrivate
            isInside
          />
          <Route
            path="/wms/integration/"
            exact
            component={Integration}
            isPrivate
            isInside
          />
          <Route
            path="/wms/followAge/"
            exact
            component={FollowProductAge}
            isPrivate
            isInside
          />
          <Route
            path="/wms/followWave/"
            exact
            component={FollowWave}
            isPrivate
            isInside
          />
          <Route
            path="/wms/receivement-dashboard/"
            exact
            component={receivementDashboard}
            isPrivate
            isInside
          />
          <Route
            path="/wms/reportBatch/"
            exact
            component={ExitReport}
            isPrivate
            isInside
          />
          <Route
            path="/wms/reportBatchEntry/"
            exact
            component={ReportBatchEntry}
            isPrivate
            isInside
          />
          <Route
            path="/wms/visitPicking"
            exact
            component={ReportVisit}
            isPrivate
            isInside
          />
          <Route
            path="/wms/stockPack/"
            exact
            component={StockPack}
            isPrivate
            isInside
          />
          <Route
            path="/wms/IndicatorsOrders"
            exact
            component={IndicatorOrder}
            isPrivate
            isInside
          />
          <Route
            path="/wms/IndicatorsReceivement"
            exact
            component={IndicatorReceivement}
            isPrivate
            isInside
          />
          <Route
            path="/wms/IndicatorsPicking"
            exact
            component={IndicatorPicking}
            isPrivate
            isInside
          />
          <Route
            path="/wms/IndicatorsStreetOccupation"
            exact
            component={StreetOccupations}
            isPrivate
            isInside
          />
          <Route
            path="/wms/SupplyCreate"
            exact
            component={CreateSupply}
            isPrivate
            isInside
          />
          <Route
            path="/wms/CrossDocking-screening"
            exact
            component={Screening}
            isPrivate
            isInside
          />
          <Route
            path="/wms/CrossDocking-driversAdmin"
            exact
            component={DriversAdmin}
            isPrivate
            isInside
          />
          <Route
            path="/wms/CrossDocking-vehiclesAdmin"
            exact
            component={VechiclesAdmin}
            isPrivate
            isInside
          />
          <Route
            path="/wms/CrossDocking-routesAdmin"
            exact
            component={RoutesAdmin}
            isPrivate
            isInside
          />
          <Route
            path="/wms/CrossDocking-stock"
            exact
            component={StockDocking}
            isPrivate
            isInside
          />
          <Route
            path="/wms/Quality-Collect"
            exact
            component={Collect}
            isPrivate
            isInside
          />
          <Route
            path="/wms/Quality-Checklist"
            exact
            component={ApprovedQuality}
            isPrivate
            isInside
          />
          <Route
            path="/wms/Approve-supply"
            exact
            component={SubRomanio}
            isPrivate
            isInside
          />
          <Route
            path="/wms/Follow-SubRomanio"
            exact
            component={FollowSubRomanio}
            isPrivate
            isInside
          />
          {/* Rotas Impressao */}
          <Route
            path="/print/pack/:pack"
            exact
            component={Pack}
            isPrivate
            isInside
          />
          <Route path="/print/pack" exact component={Pack} isPrivate isInside />
          <Route
            path="/print/setup/"
            exact
            component={AgentConect}
            isPrivate
            isInside
          />
          <Route
            path="/print/newpack"
            exact
            component={CreatePack}
            isPrivate
            isInside
          />
          <Route
            path="/print/conferenceBox"
            exact
            component={ConferenceBox}
            isPrivate
            isInside
          />
          <Route
            path="/print/home/"
            exact
            component={Printhome}
            isPrivate
            isInside
          />
          <Route
            path="/print/box/"
            exact
            component={PrintOutByBox}
            isPrivate
            isInside
          />
          <Route
            path="/print/pallet/"
            exact
            component={PrintOutByPallet}
            isPrivate
            isInside
          />
          <Route
            path="/print/address/"
            exact
            component={PrintByAddress}
            isPrivate
            isInside
          />
          <Route
            path="/print/picking/"
            exact
            component={PrintByPicking}
            isPrivate
            isInside
          />
          <Route
            path="/print/client/"
            exact
            component={PrintByClient}
            isPrivate
            isInside
          />
          <Route
            path="/print/danfe/"
            exact
            component={PrintByDanfe}
            isPrivate
            isInside
          />
          <Route
            path="/print/email/"
            exact
            component={PrintByEmail}
            isPrivate
            isInside
          />
          <Route
            path="/print/product/"
            exact
            component={PrintByProduct}
            isPrivate
            isInside
          />
          <Route
            path="/print/distribution-Box/"
            exact
            component={Distribution}
            isPrivate
            isInside
          />
          {/* Rotas Bureau */}
          <Route
            path="/print/homeBureau/"
            exact
            component={PrintDistribuitionBox}
            isPrivate
            isInside
          />
          <Route
            path="/print/printByPackBureau/"
            exact
            component={PrintByPackBureau}
            isPrivate
            isInside
          />
          <Route
            path="/print/printDistribuition/"
            exact
            component={PrintByDistribuition}
            isPrivate
            isInside
          />
          {/* Rotas do Invetario */}
          <Route
            path="/wms/inventory"
            exact
            component={Inventory}
            isPrivate
            isInside
          />
          <Route
            path="/wms/Inventory-Initial"
            exact
            component={InitialInventory}
            isPrivate
            isInside
          />
          <Route
            path="/wms/Inventory-Score"
            exact
            component={ScoreReport}
            isPrivate
            isInside
          />
          <Route
            path="/wms/Inventory-Divengent"
            exact
            component={DivergentInventory}
            isPrivate
            isInside
          />
          <Route
            path="/wms/Inventory-StatusAddress"
            exact
            component={StatusAddressInventory}
            isPrivate
            isInside
          />
          <Route
            path="/wms/Inventory-DivergentProduct"
            exact
            component={ProductDivergent}
            isPrivate
            isInside
          />
          <Route
            path="/wms/Inventory-Parametrization"
            exact
            component={Paremetrization}
            isPrivate
            isInside
          />
          <Route
            path="/wms/Inventory-Productivity"
            exact
            component={ProductivityInventory}
            isPrivate
            isInside
          />
          <Route
            path="/wms/Inventory-LogAddress"
            exact
            component={LogAddress}
            isPrivate
            isInside
          />
          <Route
            path="/wms/Inventory-AddressDuplicated"
            exact
            component={AddressDuplicateds}
            isPrivate
            isInside
          />
          <Route
            path="/wms/Inventory-InventoryFinally"
            exact
            component={InventoryFinally}
            isPrivate
            isInside
          />
          <Route
            path="/wms/Inventory-Dashboard"
            exact
            component={InventoryDashboard}
            isPrivate
            isInside
          />
          <Route path="/401" component={Error401} />
          <Route path="*" component={Error400} />
        </Switch>
      </InventorySelectedProvider>
    </BrowserRouter>
  );
};

export default routes;
